import * as Yup from "yup"
import React, { useState, useEffect, useRef } from "react"
import moment from "moment"
import omit from "lodash.omit"
import DatePicker from "react-datepicker"
import { useToasts } from "react-toast-notifications"
import { useTranslation } from "react-i18next"
import { Formik, Form } from "formik"

// Components
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Button,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tfoot,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  FormControl,
  useDisclosure,
} from "@chakra-ui/react"
import { Plus } from "@styled-icons/fa-solid"
import { ThreeDotsVertical } from "@styled-icons/bootstrap/ThreeDotsVertical"
import CustomInput from "../../../../chakra/CustomInput"

// Utils
import { cleanNotNumbers } from "../../../../../utils/cleanNotNumber"

// Styles
import { EmptyMessage } from "../../styles"

const MedicalMonitoring = ({
  firebase,
  user,
  contact,
  session,
  isFromQuestions,
}) => {
  const { addToast } = useToasts()
  const { t } = useTranslation()

  const {
    isOpen: isAddMedicationOpen,
    onOpen: onOpenAddMedication,
    onClose: onCloseAddMedication,
  } = useDisclosure()
  const {
    isOpen: isAddDetailsOpen,
    onOpen: onOpenAddDetails,
    onClose: onCloseAddDetails,
  } = useDisclosure()
  const {
    isOpen: isVaccineOpen,
    onOpen: onOpenVaccine,
    onClose: onCloseVaccine,
  } = useDisclosure()

  const addDrugRef = useRef()
  const addDetailsRef = useRef()
  const addVaccineRef = useRef()

  const [isAddingResult, setIsAddingResult] = useState(false)
  const [startDate, setStartDate] = useState(new Date().getTime())
  const [boosterStartDate, setBoosterStartDate] = useState(new Date().getTime())
  const [contactMedication, setContactMedication] = useState([])
  const [contactMedicalDetails, setContactMedicalDetails] = useState([])
  const [contactMedicalVaccines, setContactMedicalVaccines] = useState([])
  const [contactDiagnosis, setContactDiagnosis] = useState([])
  const [medicationToEdit, setMedicationToEdit] = useState({})

  const getMedicalFollowUp = async () => {
    const medicalRecords = await firebase.getMedicalRecords(contact.email)
    if (medicalRecords) {
      const records = medicalRecords.docs.map((d) => ({
        id: d.id,
        ...d.data(),
      }))
      const diagnoses = records.filter((item) => item.type === "diagnosis")
      const medication = records.filter((item) => item.type === "medication")
      const patientDetails = records.filter(
        (item) => item.type === "patientDetails"
      )
      const vaccines = records.filter((item) => item.type === "vaccine")
      setContactMedication(medication)
      setContactMedicalDetails(patientDetails)
      setContactMedicalVaccines(vaccines)
      setContactDiagnosis(diagnoses)
    }
  }

  useEffect(() => {
    if (contact?.email) {
      getMedicalFollowUp()
    }
  }, [contact])

  const getIsMedicationActive = (createdAt, duration) => {
    if (createdAt && duration) {
      const getDifference = moment().diff(moment(createdAt), "days")
      if (getDifference > duration) {
        return t("medication_inactive")
      }
      return t("medication_active")
    }
    return t("medication_inactive")
  }

  const handleSubmit = async (formRefValue) => {
    try {
      if (formRefValue.current) {
        formRefValue.current.handleSubmit()
      }
    } catch {
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const deleteFollowUpRecord = async (id) => {
    try {
      await firebase.deleteMedicalRecord({
        contactEmail: contact.email,
        recordId: id,
      })
      setTimeout(() => {
        getMedicalFollowUp()
        addToast(t("web_client.saved_successfully"), { appearance: "success" })
      }, 1000)
    } catch {
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const getTime = (date) => {
    const validate = !Number.isNaN(new Date(date).getTime())
    if (validate) {
      return moment(date).format("MMMM DD, YYYY")
    }
    return null
  }

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        height="calc(100vh - 240px)"
        width="100%"
        overflow="auto"
        boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
        borderRadius="16px"
        padding="20px"
      >
        <Tabs width="100%" variant="soft-rounded" colorScheme="green">
          <TabList
            backgroundColor="rgba(0, 0, 0, 0.04)"
            borderRadius="8px"
            padding="5px"
          >
            <Tab
              _focus={{
                boxShadow: "none",
                background: "white !important",
                color: "#000000",
              }}
              _selected={{
                boxShadow: "none",
                background: "white !important",
                color: "#000000",
              }}
              color="#000000"
            >
              {t("web_client.diagnoses")}
            </Tab>
            <Tab
              _focus={{
                boxShadow: "none",
                background: "white !important",
                color: "#000000",
              }}
              _selected={{
                boxShadow: "none",
                background: "white !important",
                color: "#000000",
              }}
              color="#000000"
            >
              {t("chronic_medications")}
            </Tab>
            <Tab
              _focus={{
                boxShadow: "none",
                background: "white !important",
                color: "#000000",
              }}
              _selected={{
                boxShadow: "none",
                background: "white !important",
                color: "#000000",
              }}
              color="#000000"
            >
              {t("patient_details")}
            </Tab>
            <Tab
              _focus={{
                boxShadow: "none",
                background: "white !important",
                color: "#000000",
              }}
              _selected={{
                boxShadow: "none",
                background: "white !important",
                color: "#000000",
              }}
              color="#000000"
            >
              {t("vaciness")}
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Flex flexDirection="column" width="100%">
                <Flex
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                  marginTop="50px"
                >
                  <Text as="b" fontSize="20px">
                    {t("web_client.diagnoses")}
                  </Text>
                </Flex>
                {contactDiagnosis?.length ? (
                  <TableContainer marginTop="60px">
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>{t("web_client.diagnosis")}</Th>
                          <Th>{t("web_client.cause")}</Th>
                          <Th>
                            {t("web_client.patient_disability_time_label")}
                          </Th>
                          <Th>{t("web_client.diagnosis_by")}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {contactDiagnosis.map((diagnosis) => (
                          <Tr
                            key={diagnosis?.id}
                            padding="12px 10px"
                            height="68px"
                          >
                            <Td textTransform="uppercase">
                              {diagnosis?.cause?.label}
                            </Td>
                            <Td>{diagnosis?.diagnosis?.label}</Td>
                            <Td>{diagnosis?.patientDisabilityDays}</Td>
                            <Td textTransform="capitalize">
                              {`${diagnosis?.createdBy}`} /{" "}
                              {diagnosis?.createdAt
                                ? getTime(diagnosis?.createdAt)
                                : ""}
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th>{t("web_client.diagnosis")}</Th>
                          <Th>{t("web_client.cause")}</Th>
                          <Th>
                            {t("web_client.patient_disability_time_label")}
                          </Th>
                          <Th>{t("web_client.diagnosis_by")}</Th>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </TableContainer>
                ) : (
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="20vh"
                    width="100%"
                  >
                    <EmptyMessage>
                      {t("web_client.empty_diagnoses")}
                    </EmptyMessage>
                  </Flex>
                )}
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex flexDirection="column" width="100%">
                <Flex
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                  marginTop="50px"
                >
                  <Text as="b" fontSize="20px">
                    {t("chronic_medications")}
                  </Text>
                  {session && (
                    <Button
                      size="sm"
                      variant="ghost"
                      backgroundColor="rgba(0, 0, 0, 0.06)"
                      _focus={{ outline: "none" }}
                      onClick={() => onOpenAddMedication()}
                    >
                      <Plus size="15px" color="#000000" />
                    </Button>
                  )}
                </Flex>
                {contactMedication?.length ? (
                  <TableContainer marginTop="60px">
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>{t("name")}</Th>
                          <Th>{t("concentration_frequency")}</Th>
                          <Th>{t("status")}</Th>
                          <Th>{t("prescribed_by")}</Th>
                          <Th />
                        </Tr>
                      </Thead>
                      <Tbody>
                        {contactMedication.map((medication) => (
                          <Tr
                            key={medication?.id}
                            padding="12px 10px"
                            height="68px"
                          >
                            <Td textTransform="uppercase">
                              {medication?.medicationName}
                            </Td>
                            <Td>{medication?.concentration}</Td>
                            <Td
                              textAlign="center"
                              fontSize="12px"
                              fontWeight="510"
                              height="20px"
                              borderRadius="6px"
                              color={
                                getIsMedicationActive(
                                  medication.createdAt,
                                  medication.duration
                                ) === t("medication_active")
                                  ? "#38A169"
                                  : "#E53E3E"
                              }
                              backgroundColor={
                                getIsMedicationActive(
                                  medication.createdAt,
                                  medication.duration
                                ) === t("medication_active")
                                  ? "#F0FFF4"
                                  : "#FFF5F5"
                              }
                            >
                              {getIsMedicationActive(
                                medication.createdAt,
                                medication.duration
                              )}
                            </Td>
                            <Td textTransform="capitalize">
                              {`${medication?.createdBy}`} /{" "}
                              {medication?.createdAt
                                ? getTime(medication?.createdAt)
                                : ""}
                            </Td>
                            <Td>
                              <Menu>
                                <MenuButton
                                  as={Button}
                                  rightIcon={
                                    <ThreeDotsVertical
                                      color="#000000"
                                      size={24}
                                    />
                                  }
                                />
                                <MenuList background="white">
                                  <MenuItem
                                    minH="48px"
                                    onClick={() => {
                                      setMedicationToEdit(medication)
                                      onOpenAddMedication()
                                    }}
                                  >
                                    <span>{t("edit")}</span>
                                  </MenuItem>
                                  {session !== null &&
                                    session === medication?.sessionId && (
                                      <MenuItem
                                        minH="40px"
                                        onClick={() =>
                                          deleteFollowUpRecord(medication.id)
                                        }
                                      >
                                        <span>{t("web_client.delete")}</span>
                                      </MenuItem>
                                    )}
                                </MenuList>
                              </Menu>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th>{t("name")}</Th>
                          <Th>{t("concentration_frequency")}</Th>
                          <Th>{t("status")}</Th>
                          <Th>{t("prescribed_by")}</Th>
                          <Th />
                        </Tr>
                      </Tfoot>
                    </Table>
                  </TableContainer>
                ) : (
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="20vh"
                    width="100%"
                  >
                    <EmptyMessage>{t("chronic_empty_text")}</EmptyMessage>
                  </Flex>
                )}
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex flexDirection="column" width="100%">
                <Flex
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                  marginTop="50px"
                >
                  <Text as="b" fontSize="20px">
                    {t("patient_details")}
                  </Text>
                  {session && (
                    <Button
                      size="sm"
                      variant="ghost"
                      backgroundColor="rgba(0, 0, 0, 0.06)"
                      _focus={{ outline: "none" }}
                      onClick={() => onOpenAddDetails()}
                    >
                      <Plus size="15px" color="#000000" />
                    </Button>
                  )}
                </Flex>
                {contactMedicalDetails?.length ? (
                  <TableContainer marginTop="60px">
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>{t("height")}</Th>
                          <Th>{t("weight")}</Th>
                          <Th>{t("blood_pressure")}</Th>
                          <Th>{t("date_and_owner")}</Th>
                          <Th />
                        </Tr>
                      </Thead>
                      <Tbody>
                        {contactMedicalDetails.map((details) => (
                          <Tr key={details?.id}>
                            <Td textTransform="uppercase">{details?.height}</Td>
                            <Td>{details?.weight}</Td>
                            <Td>{details?.pressure}</Td>
                            <Td textTransform="capitalize">
                              {`${details?.createdBy}`} /{" "}
                              {details?.createdAt
                                ? getTime(details?.createdAt)
                                : ""}
                            </Td>
                            <Td>
                              <Menu>
                                <MenuButton
                                  as={Button}
                                  rightIcon={
                                    <ThreeDotsVertical
                                      color="#000000"
                                      size={24}
                                    />
                                  }
                                />
                                <MenuList background="white">
                                  <MenuItem
                                    minH="48px"
                                    onClick={() => {
                                      setMedicationToEdit(details)
                                      onOpenAddDetails()
                                    }}
                                  >
                                    <span>{t("edit")}</span>
                                  </MenuItem>
                                  {session !== null &&
                                    session === details?.sessionId && (
                                      <MenuItem
                                        minH="40px"
                                        onClick={() =>
                                          deleteFollowUpRecord(details.id)
                                        }
                                      >
                                        <span>{t("web_client.delete")}</span>
                                      </MenuItem>
                                    )}
                                </MenuList>
                              </Menu>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th>{t("height")}</Th>
                          <Th>{t("weight")}</Th>
                          <Th>{t("blood_pressure")}</Th>
                          <Th>{t("date_and_owner")}</Th>
                          <Th />
                        </Tr>
                      </Tfoot>
                    </Table>
                  </TableContainer>
                ) : (
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="20vh"
                    width="100%"
                  >
                    <EmptyMessage>{t("chronic_empty_text")}</EmptyMessage>
                  </Flex>
                )}
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex flexDirection="column" width="100%">
                <Flex
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                  marginTop="50px"
                >
                  <Text as="b" fontSize="20px">
                    {t("vaciness")}
                  </Text>
                  {session && (
                    <Button
                      size="sm"
                      variant="ghost"
                      backgroundColor="rgba(0, 0, 0, 0.06)"
                      _focus={{ outline: "none" }}
                      onClick={() => onOpenVaccine()}
                    >
                      <Plus size="15px" color="#000000" />
                    </Button>
                  )}
                </Flex>
                {contactMedicalVaccines?.length ? (
                  <TableContainer marginTop="60px">
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>{t("name_and_brand")}</Th>
                          <Th>{t("concentration")}</Th>
                          <Th>{t("lote")}</Th>
                          <Th>{t("applied")}</Th>
                          <Th>{t("vaccine_booster")}</Th>
                          <Th />
                        </Tr>
                      </Thead>
                      <Tbody>
                        {contactMedicalVaccines.map((vaccine) => (
                          <Tr key={vaccine?.id}>
                            <Td textTransform="uppercase">
                              {vaccine?.vaccineName} <br />{" "}
                              {vaccine?.vaccineBrand}
                            </Td>
                            <Td>{vaccine?.concentration}</Td>
                            <Td>{vaccine?.lote}</Td>
                            <Td textTransform="capitalize">
                              {`${vaccine?.createdBy}`} /{" "}
                              {vaccine?.application
                                ? getTime(vaccine?.application)
                                : ""}
                            </Td>
                            <Td>
                              {vaccine?.boosterDose
                                ? getTime(vaccine?.boosterDose)
                                : ""}
                            </Td>
                            <Td>
                              <Menu>
                                <MenuButton
                                  as={Button}
                                  rightIcon={
                                    <ThreeDotsVertical
                                      color="#000000"
                                      size={24}
                                    />
                                  }
                                />
                                <MenuList background="white">
                                  <MenuItem
                                    minH="48px"
                                    onClick={() => {
                                      setMedicationToEdit(vaccine)
                                      setStartDate(vaccine?.application)
                                      setBoosterStartDate(vaccine?.boosterDose)
                                      onOpenVaccine()
                                    }}
                                  >
                                    <span>{t("edit")}</span>
                                  </MenuItem>
                                  {session !== null &&
                                    session === vaccine?.sessionId && (
                                      <MenuItem
                                        minH="40px"
                                        onClick={() =>
                                          deleteFollowUpRecord(vaccine.id)
                                        }
                                      >
                                        <span>{t("web_client.delete")}</span>
                                      </MenuItem>
                                    )}
                                </MenuList>
                              </Menu>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th>{t("name_and_brand")}</Th>
                          <Th>{t("concentration")}</Th>
                          <Th>{t("lote")}</Th>
                          <Th>{t("applied")}</Th>
                          <Th>{t("vaccine_booster")}</Th>
                          <Th />
                        </Tr>
                      </Tfoot>
                    </Table>
                  </TableContainer>
                ) : (
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="20vh"
                    width="100%"
                  >
                    <EmptyMessage>{t("chronic_empty_text")}</EmptyMessage>
                  </Flex>
                )}
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>

      {/* Drawer para añadir un medicamento empieza aca */}
      <Drawer
        size="sm"
        isOpen={isAddMedicationOpen}
        onClose={onCloseAddMedication}
      >
        <DrawerOverlay>
          <DrawerContent background="white">
            <DrawerCloseButton _focus={{ outline: "none" }} />
            <DrawerHeader>{t("add_medication")}</DrawerHeader>
            <DrawerBody>
              <Formik
                innerRef={addDrugRef}
                initialValues={{
                  medicationName: medicationToEdit?.medicationName
                    ? medicationToEdit.medicationName
                    : undefined,
                  concentration: medicationToEdit?.concentration
                    ? medicationToEdit.concentration
                    : undefined,
                  frequency: medicationToEdit?.frequency
                    ? medicationToEdit.frequency
                    : undefined,
                  duration: medicationToEdit?.duration
                    ? medicationToEdit.duration
                    : undefined,
                  createdByEmail: user.isImpersonating
                    ? user.impersonatingEmail
                    : user.email,
                  createdBy: `${user?.medicProfile?.title} ${user?.medicProfile?.displayName}`,
                  createdAt: medicationToEdit?.createdAt
                    ? medicationToEdit.createdAt
                    : Date.now(),
                  modifietAt: medicationToEdit.createdAt ? Date.now() : null,
                  sessionId: isFromQuestions ? session?.id : session,
                  type: "medication",
                }}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true)
                  setIsAddingResult(true)
                  try {
                    if (medicationToEdit?.createdAt) {
                      const addRegisterRecords = {
                        ...values,
                        editedBy: user?.medicProfile
                          ? `${user?.medicProfile?.title} ${user?.medicProfile?.displayName}`
                          : user.isImpersonating
                          ? user.impersonatingEmail
                          : user.email,
                        records: medicationToEdit?.records
                          ? medicationToEdit.records.concat(
                              omit(medicationToEdit, "records")
                            )
                          : [medicationToEdit],
                      }
                      await firebase.updateMedicalRecord({
                        contactEmail: contact.email,
                        record: addRegisterRecords,
                        recordId: medicationToEdit.id,
                      })
                      setMedicationToEdit({})
                    } else {
                      await firebase.addMedicalRecord({
                        contactEmail: contact.email,
                        record: values,
                      })
                    }
                    setTimeout(async () => {
                      const medicalRecords = await firebase.getMedicalRecords(
                        contact.email
                      )
                      const records = medicalRecords.docs.map((d) => ({
                        id: d.id,
                        ...d.data(),
                      }))
                      const medication = records.filter(
                        (item) => item.type === "medication"
                      )
                      setContactMedication(medication)
                      actions.setSubmitting(false)
                      onCloseAddMedication()
                      setIsAddingResult(false)
                    }, 1000)
                  } catch (error) {
                    setIsAddingResult(false)
                    addToast(t("unexpected_error"), { appearance: "error" })
                  }
                }}
                validationSchema={Yup.object({
                  medicationName: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                  concentration: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                  frequency: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                  duration: Yup.number().required(
                    t("error_all_fields_required")
                  ),
                  createdByEmail: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                  createdAt: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                })}
              >
                {(props) => (
                  <Form>
                    <VStack spacing={7}>
                      <FormControl
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Box mt="40px">
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.64)"
                            mb="8px"
                          >
                            {t("medication_name")}
                          </Text>
                          <CustomInput
                            name="medicationName"
                            id="medicationName"
                            placeholder=""
                            showErrorMessage={
                              !!props.errors.medicationName &&
                              props.submitCount > 0
                            }
                            errorMessage={props.errors.medicationName}
                            handleInputChange={(e) =>
                              props.setFieldValue(
                                "medicationName",
                                e.target.value
                              )
                            }
                            defaultValue={props.values.medicationName}
                          />
                        </Box>

                        <Box mt="22px">
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.64)"
                            mb="8px"
                          >
                            {t("concentration")}
                          </Text>
                          <CustomInput
                            name="concentration"
                            id="concentration"
                            placeholder=""
                            containerHeight="auto"
                            showErrorMessage={
                              !!props.errors.concentration &&
                              props.submitCount > 0
                            }
                            errorMessage={props.errors.concentration}
                            handleInputChange={(e) =>
                              props.setFieldValue(
                                "concentration",
                                e.target.value
                              )
                            }
                            defaultValue={props.values.concentration}
                          />

                          <Text
                            fontSize="14px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.36)"
                          >
                            {t("medication_example")}
                          </Text>
                        </Box>

                        <Box mt="22px">
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.64)"
                            mb="8px"
                          >
                            {t("frequency")}
                          </Text>
                          <CustomInput
                            name="frequency"
                            id="frequency"
                            placeholder=""
                            containerHeight="auto"
                            showErrorMessage={
                              !!props.errors.frequency && props.submitCount > 0
                            }
                            errorMessage={props.errors.frequency}
                            handleInputChange={(e) =>
                              props.setFieldValue("frequency", e.target.value)
                            }
                            defaultValue={props.values.frequency}
                          />

                          <Text
                            fontSize="14px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.36)"
                          >
                            {t("frecuency_example")}
                          </Text>
                        </Box>
                        <Box mt="22px">
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.64)"
                            mb="8px"
                          >
                            {t("duration_label")}
                          </Text>
                          <CustomInput
                            name="duration"
                            id="duration"
                            placeholder=""
                            containerHeight="auto"
                            showErrorMessage={
                              !!props.errors.duration && props.submitCount > 0
                            }
                            errorMessage={props.errors.duration}
                            handleInputChange={(e) =>
                              props.setFieldValue(
                                "duration",
                                Number(cleanNotNumbers(e.target.value))
                              )
                            }
                            defaultValue={props.values.duration}
                            type="number"
                          />

                          <Text
                            fontSize="14px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.36)"
                          >
                            {t("duration_example")}
                          </Text>
                        </Box>
                      </FormControl>
                    </VStack>
                  </Form>
                )}
              </Formik>
            </DrawerBody>
            <DrawerFooter>
              <Button
                variant="solid"
                color="blackAlpha.700"
                fontWeight="700"
                width="270px"
                height="48px"
                fontSize="18px"
                border=" 1px solid rgba(0, 0, 0, 0.08)"
                borderRadius="8.5px"
                mr={3}
                onClick={() => {
                  setMedicationToEdit({})
                  onCloseAddMedication()
                }}
              >
                {t("cancel")}
              </Button>
              <Button
                background="blackAlpha.100"
                color="black"
                fontSize="18px"
                fontWeight="400"
                width="270px"
                height="48px"
                borderRadius="8.5px"
                _hover={{
                  background: "brand.primary",
                  color: "white",
                }}
                _focus={{ outline: "none" }}
                mr={3}
                onClick={() => handleSubmit(addDrugRef)}
                isLoading={isAddingResult}
              >
                {medicationToEdit.createdAt
                  ? t("save_changes")
                  : t("create_new_label")}
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      {/* Drawer para añadir peso, altura y presión empieza aca */}
      <Drawer size="sm" isOpen={isAddDetailsOpen} onClose={onCloseAddDetails}>
        <DrawerOverlay>
          <DrawerContent background="white">
            <DrawerCloseButton _focus={{ outline: "none" }} />
            <DrawerHeader>{t("patient_details")}</DrawerHeader>
            <DrawerBody>
              <Formik
                innerRef={addDetailsRef}
                initialValues={{
                  height: medicationToEdit?.height
                    ? medicationToEdit.height
                    : undefined,
                  weight: medicationToEdit?.weight
                    ? medicationToEdit.weight
                    : undefined,
                  pressure: medicationToEdit?.pressure
                    ? medicationToEdit.pressure
                    : undefined,
                  createdByEmail: user.isImpersonating
                    ? user.impersonatingEmail
                    : user.email,
                  createdBy: `${user?.medicProfile?.title} ${user?.medicProfile?.displayName}`,
                  createdAt: medicationToEdit?.createdAt
                    ? medicationToEdit.createdAt
                    : Date.now(),
                  modifietAt: medicationToEdit.createdAt ? Date.now() : null,
                  sessionId: isFromQuestions ? session?.id : session,
                  type: "patientDetails",
                }}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true)
                  setIsAddingResult(true)
                  try {
                    if (medicationToEdit?.createdAt) {
                      const addRegisterRecords = {
                        ...values,
                        editedBy: user?.medicProfile
                          ? `${user?.medicProfile?.title} ${user?.medicProfile?.displayName}`
                          : user.isImpersonating
                          ? user.impersonatingEmail
                          : user.email,
                        records: medicationToEdit?.records
                          ? medicationToEdit.records.concat(
                              omit(medicationToEdit, "records")
                            )
                          : [medicationToEdit],
                      }
                      await firebase.updateMedicalRecord({
                        contactEmail: contact.email,
                        record: addRegisterRecords,
                        recordId: medicationToEdit.id,
                      })
                      setMedicationToEdit({})
                    } else {
                      await firebase.addMedicalRecord({
                        contactEmail: contact.email,
                        record: values,
                      })
                    }
                    setTimeout(async () => {
                      const medicalRecords = await firebase.getMedicalRecords(
                        contact.email
                      )
                      const records = medicalRecords.docs.map((d) => ({
                        id: d.id,
                        ...d.data(),
                      }))
                      const patientDetails = records.filter(
                        (item) => item.type === "patientDetails"
                      )
                      setContactMedicalDetails(patientDetails)
                      onCloseAddDetails()
                      actions.setSubmitting(false)
                      setIsAddingResult(false)
                    }, 1000)
                  } catch (error) {
                    setIsAddingResult(false)
                    addToast(t("unexpected_error"), { appearance: "error" })
                  }
                }}
                validationSchema={Yup.object({
                  height: Yup.string().required(t("error_all_fields_required")),
                  weight: Yup.string().required(t("error_all_fields_required")),
                  pressure: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                  createdByEmail: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                  createdAt: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                })}
              >
                {(props) => (
                  <Form>
                    <VStack spacing={7}>
                      <FormControl
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Box mt="40px">
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.64)"
                            mb="8px"
                          >
                            {t("weight")}
                          </Text>
                          <CustomInput
                            name="weight"
                            id="weight"
                            placeholder=""
                            showErrorMessage={
                              !!props.errors.weight && props.submitCount > 0
                            }
                            errorMessage={props.errors.weight}
                            handleInputChange={(e) =>
                              props.setFieldValue("weight", e.target.value)
                            }
                            defaultValue={props.values.weight}
                          />
                        </Box>

                        <Box mt="22px">
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.64)"
                            mb="8px"
                          >
                            {t("height")}
                          </Text>
                          <CustomInput
                            name="height"
                            id="height"
                            placeholder=""
                            showErrorMessage={
                              !!props.errors.height && props.submitCount > 0
                            }
                            errorMessage={props.errors.height}
                            handleInputChange={(e) =>
                              props.setFieldValue("height", e.target.value)
                            }
                            defaultValue={props.values.height}
                          />
                        </Box>

                        <Box mt="22px">
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.64)"
                            mb="8px"
                          >
                            {t("pressure")}
                          </Text>
                          <CustomInput
                            name="pressure"
                            id="pressure"
                            placeholder=""
                            showErrorMessage={
                              !!props.errors.pressure && props.submitCount > 0
                            }
                            errorMessage={props.errors.frequency}
                            handleInputChange={(e) =>
                              props.setFieldValue("pressure", e.target.value)
                            }
                            defaultValue={props.values.pressure}
                          />
                        </Box>
                      </FormControl>
                    </VStack>
                  </Form>
                )}
              </Formik>
            </DrawerBody>
            <DrawerFooter>
              <Button
                variant="solid"
                color="blackAlpha.700"
                fontWeight="700"
                width="270px"
                height="48px"
                fontSize="18px"
                border=" 1px solid rgba(0, 0, 0, 0.08)"
                borderRadius="8.5px"
                mr={3}
                onClick={() => {
                  setMedicationToEdit({})
                  onCloseAddDetails()
                }}
              >
                {t("cancel")}
              </Button>
              <Button
                background="blackAlpha.100"
                color="black"
                fontSize="18px"
                fontWeight="400"
                width="270px"
                height="48px"
                borderRadius="8.5px"
                _hover={{
                  background: "brand.primary",
                  color: "white",
                }}
                _focus={{ outline: "none" }}
                mr={3}
                onClick={() => handleSubmit(addDetailsRef)}
                isLoading={isAddingResult}
              >
                {medicationToEdit.createdAt
                  ? t("save_changes")
                  : t("create_new_label")}
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      {/* Drawer para añadir Vacuna empieza aca */}
      <Drawer size="sm" isOpen={isVaccineOpen} onClose={onCloseVaccine}>
        <DrawerOverlay>
          <DrawerContent background="white">
            <DrawerCloseButton _focus={{ outline: "none" }} />
            <DrawerHeader>{t("vaciness")}</DrawerHeader>
            <DrawerBody>
              <Formik
                innerRef={addVaccineRef}
                initialValues={{
                  vaccineName: medicationToEdit?.vaccineName
                    ? medicationToEdit.vaccineName
                    : undefined,
                  vaccineBrand: medicationToEdit?.vaccineBrand
                    ? medicationToEdit.vaccineBrand
                    : undefined,
                  concentration: medicationToEdit?.concentration
                    ? medicationToEdit.concentration
                    : undefined,
                  lote: medicationToEdit?.lote
                    ? medicationToEdit.lote
                    : undefined,
                  application: startDate,
                  boosterDose: boosterStartDate,
                  createdByEmail: user.isImpersonating
                    ? user.impersonatingEmail
                    : user.email,
                  createdBy: `${user?.medicProfile?.title} ${user?.medicProfile?.displayName}`,
                  createdAt: medicationToEdit?.createdAt
                    ? medicationToEdit.createdAt
                    : Date.now(),
                  modifietAt: medicationToEdit.createdAt ? Date.now() : null,
                  sessionId: isFromQuestions ? session?.id : session,
                  type: "vaccine",
                }}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true)
                  setIsAddingResult(true)
                  try {
                    if (medicationToEdit?.createdAt) {
                      const addRegisterRecords = {
                        ...values,
                        editedBy: user?.medicProfile
                          ? `${user?.medicProfile?.title} ${user?.medicProfile?.displayName}`
                          : user.isImpersonating
                          ? user.impersonatingEmail
                          : user.email,
                        records: medicationToEdit?.records
                          ? medicationToEdit.records.concat(
                              omit(medicationToEdit, "records")
                            )
                          : [medicationToEdit],
                      }
                      await firebase.updateMedicalRecord({
                        contactEmail: contact.email,
                        record: addRegisterRecords,
                        recordId: medicationToEdit.id,
                      })
                      setMedicationToEdit({})
                    } else {
                      await firebase.addMedicalRecord({
                        contactEmail: contact.email,
                        record: values,
                      })
                    }
                    setTimeout(async () => {
                      const medicalRecords = await firebase.getMedicalRecords(
                        contact.email
                      )
                      const records = medicalRecords.docs.map((d) => ({
                        id: d.id,
                        ...d.data(),
                      }))
                      const vaccines = records.filter(
                        (item) => item.type === "vaccine"
                      )
                      setContactMedicalVaccines(vaccines)
                      onCloseVaccine()
                      actions.setSubmitting(false)
                      setIsAddingResult(false)
                    }, 1000)
                  } catch (error) {
                    setIsAddingResult(false)
                    addToast(t("unexpected_error"), { appearance: "error" })
                  }
                }}
                validationSchema={Yup.object({
                  vaccineName: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                  vaccineBrand: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                  concentration: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                  lote: Yup.string().required(t("error_all_fields_required")),
                  application: Yup.number().required(
                    t("error_all_fields_required")
                  ),
                  boosterDose: Yup.number().required(
                    t("error_all_fields_required")
                  ),
                  createdByEmail: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                  createdAt: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                })}
              >
                {(props) => (
                  <Form>
                    <VStack spacing={7}>
                      <FormControl
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Box mt="40px">
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.64)"
                            mb="8px"
                          >
                            {t("name")}
                          </Text>
                          <CustomInput
                            name="vaccineName"
                            id="vaccineName"
                            placeholder=""
                            showErrorMessage={
                              !!props.errors.vaccineName &&
                              props.submitCount > 0
                            }
                            errorMessage={props.errors.vaccineName}
                            handleInputChange={(e) =>
                              props.setFieldValue("vaccineName", e.target.value)
                            }
                            defaultValue={props.values.vaccineName}
                          />
                        </Box>

                        <Box mt="22px">
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.64)"
                            mb="8px"
                          >
                            {t("brand")}
                          </Text>
                          <CustomInput
                            name="vaccineBrand"
                            id="vaccineBrand"
                            placeholder=""
                            showErrorMessage={
                              !!props.errors.vaccineBrand &&
                              props.submitCount > 0
                            }
                            errorMessage={props.errors.vaccineBrand}
                            handleInputChange={(e) =>
                              props.setFieldValue(
                                "vaccineBrand",
                                e.target.value
                              )
                            }
                            defaultValue={props.values.vaccineBrand}
                          />
                        </Box>

                        <Box mt="22px">
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.64)"
                            mb="8px"
                          >
                            {t("concentration")}
                          </Text>
                          <CustomInput
                            name="concentration"
                            id="concentration"
                            placeholder=""
                            showErrorMessage={
                              !!props.errors.concentration &&
                              props.submitCount > 0
                            }
                            errorMessage={props.errors.concentration}
                            handleInputChange={(e) =>
                              props.setFieldValue(
                                "concentration",
                                e.target.value
                              )
                            }
                            defaultValue={props.values.concentration}
                          />
                        </Box>
                        <Box mt="22px">
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.64)"
                            mb="8px"
                          >
                            {t("lote")}
                          </Text>
                          <CustomInput
                            name="lote"
                            id="lote"
                            placeholder=""
                            showErrorMessage={
                              !!props.errors.lote && props.submitCount > 0
                            }
                            errorMessage={props.errors.lote}
                            handleInputChange={(e) =>
                              props.setFieldValue("lote", e.target.value)
                            }
                            defaultValue={props.values.lote}
                          />
                        </Box>
                        <Box mt="22px">
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="rgba(0, 0, 0, 0.64)"
                            mb="8px"
                          >
                            {t("application")}
                          </Text>
                          <Box
                            display="flex"
                            alignItems="center"
                            width="348px"
                            height="40px"
                            border="1px solid rgba(0, 0, 0, 0.08)"
                            borderRadius="6px"
                            pl="20px"
                          >
                            <DatePicker
                              locale="es"
                              dateFormat="dd/MM/yyyy"
                              selected={startDate}
                              onChange={(date) => {
                                setStartDate(date)
                                const parseDate = new Date(date)
                                props.setFieldValue(
                                  "application",
                                  parseDate.getTime()
                                )
                              }}
                            />
                          </Box>
                          <Box mt="22px">
                            <Text
                              fontSize="16px"
                              fontWeight="500"
                              color="rgba(0, 0, 0, 0.64)"
                              mb="8px"
                            >
                              {t("vaccine_booster")}
                            </Text>
                            <Box
                              display="flex"
                              alignItems="center"
                              width="348px"
                              height="40px"
                              border="1px solid rgba(0, 0, 0, 0.08)"
                              borderRadius="6px"
                              pl="20px"
                            >
                              <DatePicker
                                locale="es"
                                dateFormat="dd/MM/yyyy"
                                selected={boosterStartDate}
                                onChange={(date) => {
                                  setBoosterStartDate(date)
                                  const parseDate = new Date(date)
                                  props.setFieldValue(
                                    "boosterDose",
                                    parseDate.getTime()
                                  )
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </FormControl>
                    </VStack>
                  </Form>
                )}
              </Formik>
            </DrawerBody>
            <DrawerFooter>
              <Button
                variant="solid"
                color="blackAlpha.700"
                fontWeight="700"
                width="270px"
                height="48px"
                fontSize="18px"
                border=" 1px solid rgba(0, 0, 0, 0.08)"
                borderRadius="8.5px"
                mr={3}
                onClick={() => {
                  setMedicationToEdit({})
                  onCloseVaccine()
                }}
              >
                {t("cancel")}
              </Button>
              <Button
                background="blackAlpha.100"
                color="black"
                fontSize="18px"
                fontWeight="400"
                width="270px"
                height="48px"
                borderRadius="8.5px"
                _hover={{
                  background: "brand.primary",
                  color: "white",
                }}
                _focus={{ outline: "none" }}
                mr={3}
                onClick={() => handleSubmit(addVaccineRef)}
                isLoading={isAddingResult}
              >
                {medicationToEdit.createdAt
                  ? t("save_changes")
                  : t("create_new_label")}
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}

export default MedicalMonitoring
