import React, { useEffect, useState, useMemo } from "react"
import get from "lodash.get"
import { useTranslation } from "react-i18next"
import { FieldArray, Field } from "formik"
import { v4 as uuidv4 } from "uuid"
import { TrashAlt } from "@styled-icons/fa-solid/TrashAlt"
import {
  Box,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  VStack,
  Text,
  Flex,
  useMediaQuery,
  Textarea,
  Button,
} from "@chakra-ui/react"
import CustomInput from "../CustomInput"
import Countries from "./data/countries.json"
import LocationSelector from "../LocationSelector"

const CountryFormControl = chakra(FormControl, {
  baseStyle: {
    display: { base: "auto", md: "inline-flex" },
    mt: 2,
    mb: 2,
  },
})

const CountryFormLabel = chakra(FormLabel, {
  baseStyle: {
    minW: { base: "auto", md: "180px" },
    mt: 3,
    ml: 6,
  },
})

const CountrySelect = chakra(Select, {
  baseStyle: {
    minW: { base: "auto", md: "532px" },
    my: 1,
    border: "1px solid #E2E8F0 !important",
  },
})

const CountriesSelector = ({
  onChange,
  errors,
  values,
  hasSubmitions,
  submitCount,
  coordsError,
  isFromAccount = false,
  organizations = {},
  accessMode,
}) => {
  const { t } = useTranslation()
  const [isMobile] = useMediaQuery("(max-width: 800px)")
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)
  const [officeOptions, setOfficeOptions] = useState([{}])
  const [, setSelectedDistrict] = useState(null)
  const docOrgs = useMemo(() => {
    const parsedOrgs =
      accessMode === "business"
        ? []
        : [
            {
              value: "private",
              label: "Privado",
            },
          ]

    Object.keys(organizations)
      ?.filter((o) => organizations[o].active)
      ?.forEach((org) =>
        parsedOrgs.push({
          label: organizations[org].businessName,
          value: organizations[org].id,
        })
      )

    return parsedOrgs
  }, [organizations])

  useEffect(() => {
    if (isFromAccount && values?.offices?.length >= 1) {
      values?.offices?.forEach((office, index) => {
        const newCities = selectedCountry?.states?.find(
          (st) => st.name === office.state
        )?.cities

        const newDistricts = newCities?.find((c) => c.name === office.city)
          ?.districts

        const officeId = office?.id
        setOfficeOptions((prevOptions) => {
          const newOptions = [...prevOptions]
          newOptions[index] = {
            ...newOptions[index],
            cities: newCities,
            districts: newDistricts || [],
            id: officeId,
          }
          return newOptions
        })
      })
    }
  }, [isFromAccount, selectedCountry])

  const handleStateChange = (index, newState) => {
    if (values.offices?.[index]?.state !== newState) {
      onChange(`offices.${index}.state`, newState)
      onChange(`offices.${index}.city`, null)
      onChange(`offices.${index}.district`, "")

      const newCities = selectedCountry?.states?.find(
        (st) => st.name === newState
      )?.cities

      const officeId = values.offices?.[index]?.id
      setOfficeOptions((prevOptions) => {
        const newOptions = [...prevOptions]
        newOptions[index] = {
          ...newOptions[index],
          cities: newCities,
          districts: newCities[0]?.districts || [],
          id: officeId,
        }
        return newOptions
      })
      setSelectedState(newState)
    }
  }

  const handleCityChange = (index, newCity) => {
    if (values.offices?.[index]?.city !== newCity) {
      const officeId = values.offices?.[index]?.id
      onChange(`offices.${index}.city`, newCity)
      onChange(`offices.${index}.district`, "")
      setOfficeOptions((prevOptions) => {
        const newOptions = [...prevOptions]
        newOptions[index] = {
          ...newOptions[index],
          districts: newOptions
            ?.find((o) => o.id === officeId)
            ?.cities.find((c) => c.name === newCity)?.districts,
        }
        return newOptions
      })
      setSelectedCity(newCity)
    }
  }

  useEffect(() => {
    if (values?.country) {
      const countrySelected = Countries.find(
        (country) => country.iso2 === values.country
      )
      if (countrySelected) {
        setSelectedCountry(countrySelected)
      }
    }
  }, [values])

  useEffect(() => {
    if (selectedCountry) {
      setSelectedState(selectedCountry.states[0])
      setSelectedCity(selectedCountry.states[0].cities[0])
    }

    if (!values?.multipleOfficesEnabled && values?.offices?.length >= 1) {
      onChange("offices", [
        {
          state: null,
          city: null,
          district: null,
          addressDetails: null,
          coordinates: "",
        },
      ])
    }
  }, [selectedCountry, values?.multipleOfficesEnabled])

  const countryError =
    (!values.multipleOfficesEnabled && values?.country === null) ||
    get(errors, "country", false)
  const stateError =
    (!values.multipleOfficesEnabled && values?.state === null) ||
    get(errors, "state", false)
  const cityError =
    (!values.multipleOfficesEnabled && values?.city === null) ||
    get(errors, "city", false)
  const districtError = get(errors, "district", false)

  return (
    <>
      <CountryFormControl isInvalid={countryError && hasSubmitions >= 1}>
        <CountryFormLabel>
          <Text
            fontSize="14px"
            fontWeight="600"
            lineHeight="20px"
            color="#1A202C"
            mt="5px"
          >
            * {t("q7_business_country_label")}
          </Text>
        </CountryFormLabel>
        <VStack alignItems="flex-start">
          <Box>
            <CountrySelect
              placeholder={t("select_type", {
                type: t("country"),
              })}
              value={values?.country}
              onChange={(e) => {
                const countrySelected = Countries?.find(
                  (country) => country.iso2 === e.target.value
                )
                onChange("country", get(countrySelected, "iso2"))
                setSelectedCountry(countrySelected)
                if (
                  get(selectedCountry, "name", false) !== countrySelected?.name
                ) {
                  setSelectedState(null)
                  setSelectedCity(null)
                  setSelectedDistrict("")
                  if (values?.multipleOfficesEnabled) {
                    values?.offices?.forEach((office, index) => {
                      onChange(
                        `offices.${index}.country`,
                        countrySelected?.name
                      )
                      onChange(`offices.${index}.state`, null)
                      onChange(`offices.${index}.city`, null)
                      onChange(`offices.${index}.district`, "")
                    })
                  } else {
                    onChange("state", null)
                    onChange("city", null)
                    onChange("district", "")
                  }
                }
              }}
              id="country"
              name="country"
            >
              {Countries?.map((country) => (
                <option key={country.id} value={country.iso2}>
                  {country.name}
                </option>
              ))}
            </CountrySelect>
          </Box>
          <Box>
            <FormErrorMessage mt={-1} mb={-2}>
              {countryError}
            </FormErrorMessage>
          </Box>
        </VStack>
      </CountryFormControl>
      {values?.multipleOfficesEnabled && selectedCountry && (
        <>
          <hr />
          {values?.offices?.length === 0 && (
            <Flex
              flexDirection="row"
              alignItems="center"
              marginLeft={isMobile ? "0" : "24px"}
              marginBottom="10px"
            >
              <Box marginRight="10px" height="22px">
                <Text fontSize="14px" fontWeight="600" color="red">
                  *
                </Text>
              </Box>
              <Text fontSize="14px" fontWeight="600" color="red">
                Es necesario que agregue al menos un consultorio
              </Text>
            </Flex>
          )}

          <FieldArray
            name="offices"
            render={(arrayHelpers) => (
              <div>
                {values?.offices?.map((office, index) => {
                  const officeWithError = get(errors, `offices`, false)
                  const officeCityError =
                    officeWithError && office?.city === null
                  const officeStateError =
                    officeWithError && office?.state === null
                  const officeDistricError =
                    officeWithError && office?.district === null
                  const officeAddressError =
                    officeWithError &&
                    (office?.addressDetails === null ||
                      office?.addressDetails === "")
                  const officeCoordsError =
                    officeWithError &&
                    (office?.coordinates === null || office?.coordinates === "")
                  return (
                    <div key={office.id}>
                      <Flex
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        m="0 15px"
                      >
                        <Text color="#576EF5" pl="15px" pt="20px">{`${t(
                          "web_client.office"
                        )}: ${office.alias || office.state || index} - ${
                          office.city || index
                        }`}</Text>
                        <Box
                          height="24px"
                          width="24px"
                          _hover={{
                            transform: "scale(1, 1.1)",
                            cursor: "pointer",
                            color: "#E53E3E",
                          }}
                          onClick={() => {
                            setOfficeOptions((prevOptions) => {
                              const newOptions = prevOptions.filter(
                                (option) => option.id !== office.id
                              )
                              return newOptions
                            })
                            arrayHelpers.remove(index)
                          }}
                        >
                          <TrashAlt size="24" />
                        </Box>
                      </Flex>

                      <Field
                        name={`offices.${index}`}
                        render={() => (
                          <>
                            {selectedCountry && (
                              <CountryFormControl
                                isInvalid={
                                  officeStateError && hasSubmitions >= 1
                                }
                              >
                                <CountryFormLabel>
                                  <Text
                                    fontSize="14px"
                                    fontWeight="600"
                                    lineHeight="20px"
                                    color="#1A202C"
                                    mt="5px"
                                  >
                                    *{" "}
                                    {t(
                                      `web_client.country_state_label_${selectedCountry.iso2}`
                                    )}
                                  </Text>
                                </CountryFormLabel>
                                <VStack alignItems="flex-start">
                                  <Box>
                                    <CountrySelect
                                      placeholder={t(
                                        `web_client.country_state_label_${selectedCountry.iso2}`
                                      )}
                                      value={office.state}
                                      onChange={(e) => {
                                        const stateSelected = selectedCountry.states.find(
                                          (i) => i?.name === e.target.value
                                        )
                                        handleStateChange(
                                          index,
                                          stateSelected?.name
                                        )
                                      }}
                                      id={`offices.${index}.state`}
                                      name={`offices.${index}.state`}
                                    >
                                      {selectedCountry?.states?.map((state) => (
                                        <option
                                          key={state.id}
                                          value={state.name}
                                        >
                                          {state.name}
                                        </option>
                                      ))}
                                    </CountrySelect>
                                  </Box>
                                  <Box>
                                    <FormErrorMessage mt={-1} mb={-2}>
                                      {officeStateError}
                                    </FormErrorMessage>
                                  </Box>
                                </VStack>
                              </CountryFormControl>
                            )}
                            {office.state && (
                              <CountryFormControl
                                isInvalid={cityError && hasSubmitions >= 1}
                              >
                                <CountryFormLabel>
                                  <Text
                                    fontSize="14px"
                                    fontWeight="600"
                                    lineHeight="20px"
                                    color="#1A202C"
                                    mt="5px"
                                  >
                                    *{" "}
                                    {t(
                                      `web_client.country_city_label_${selectedCountry.iso2}`
                                    )}
                                  </Text>
                                </CountryFormLabel>
                                <VStack alignItems="flex-start">
                                  <Box>
                                    <CountrySelect
                                      placeholder={t(
                                        `web_client.country_city_label_${selectedCountry.iso2}`
                                      )}
                                      value={office.city}
                                      onChange={(e) => {
                                        const selectedOfficeCity = officeOptions
                                          ?.find((o) => o.id === office.id)
                                          ?.cities.find(
                                            (i) => i.name === e.target.value
                                          )
                                        handleCityChange(
                                          index,
                                          selectedOfficeCity?.name
                                        )
                                      }}
                                      id={`offices.${index}.city`}
                                      name={`offices.${index}.city`}
                                    >
                                      {officeOptions
                                        ?.find((o) => o.id === office.id)
                                        ?.cities?.map((city) => (
                                          <option
                                            key={city.id}
                                            value={city.name}
                                          >
                                            {city.name}
                                          </option>
                                        ))}
                                    </CountrySelect>
                                  </Box>
                                  <Box>
                                    <FormErrorMessage mt={-1} mb={-2}>
                                      {officeCityError}
                                    </FormErrorMessage>
                                  </Box>
                                </VStack>
                              </CountryFormControl>
                            )}
                            {office.city &&
                              officeOptions?.find((o) => o.id === office.id)
                                ?.districts && (
                                <CountryFormControl
                                  isInvalid={
                                    districtError && hasSubmitions >= 1
                                  }
                                >
                                  <CountryFormLabel>
                                    <Text
                                      fontSize="14px"
                                      fontWeight="600"
                                      lineHeight="20px"
                                      color="#1A202C"
                                      mt="5px"
                                    >
                                      *{" "}
                                      {t(
                                        "web_client.country_district_label_CR"
                                      )}
                                    </Text>
                                  </CountryFormLabel>
                                  <VStack alignItems="flex-start">
                                    <Box>
                                      <CountrySelect
                                        placeholder={t(
                                          "web_client.country_district_label_CR"
                                        )}
                                        value={office.district}
                                        onChange={(e) => {
                                          const selectedDistrictValue = officeOptions
                                            ?.find((o) => o.id === office.id)
                                            ?.districts.find(
                                              (i) => i.name === e.target.value
                                            )
                                          onChange(
                                            `offices.${index}.district`,
                                            selectedDistrictValue.name
                                          )
                                          setSelectedDistrict(
                                            selectedDistrictValue
                                          )
                                        }}
                                        id={`offices.${index}.district`}
                                        name={`offices.${index}.district`}
                                      >
                                        {officeOptions
                                          ?.find((o) => o.id === office.id)
                                          ?.districts?.map((district) => (
                                            <option
                                              key={district.name}
                                              value={district.name}
                                            >
                                              {district.name}
                                            </option>
                                          ))}
                                      </CountrySelect>
                                    </Box>
                                    <Box>
                                      <FormErrorMessage mt={-1} mb={-2}>
                                        {officeDistricError}
                                      </FormErrorMessage>
                                    </Box>
                                  </VStack>
                                </CountryFormControl>
                              )}
                            <Box width="100%" backgroundColor="#F7FAFC">
                              <Flex
                                flexDirection={isMobile ? "column" : "row"}
                                minHeight="84px"
                                padding={isMobile ? "10px 20px" : "10px 0"}
                                justifyContent="space-between"
                                alignItems={isMobile ? "flex-start" : "center"}
                                width="97%"
                              >
                                <Flex
                                  flexDirection="row"
                                  alignItems="center"
                                  marginLeft={isMobile ? "0" : "24px"}
                                >
                                  <Box marginRight="10px" height="22px">
                                    *
                                  </Box>
                                  <Text
                                    fontSize="14px"
                                    fontWeight="600"
                                    color="#1A202C"
                                  >
                                    {t("address_street1_label")}
                                  </Text>
                                </Flex>
                                <Box>
                                  <Textarea
                                    _hover={{}}
                                    value={office.addressDetails}
                                    variant="outline"
                                    _placeholder={{
                                      color: "gray.500",
                                    }}
                                    borderColor={
                                      officeAddressError && submitCount >= 1
                                        ? "red.500"
                                        : "#E2E8F0"
                                    }
                                    focusBorderColor="#E2E8F0"
                                    borderRadius="6px"
                                    color="gray.500"
                                    fontSize="16px"
                                    placeholder=""
                                    width={isMobile ? "320px" : "528px"}
                                    height="32px"
                                    type="text"
                                    name={`offices.${index}.addressDetails`}
                                    id={`offices.${index}.addressDetails`}
                                    onChange={(e) =>
                                      onChange(
                                        `offices.${index}.addressDetails`,
                                        e.target.value
                                      )
                                    }
                                  />
                                  {officeAddressError && submitCount >= 1 && (
                                    <Text
                                      fontSize="12px"
                                      color="red.500"
                                      fontWeight="500"
                                      width="500px"
                                    >
                                      {errors?.offices?.[index]
                                        ?.addressDetails ||
                                        t("error_all_fields_required")}
                                    </Text>
                                  )}
                                </Box>
                              </Flex>
                            </Box>
                            <Box
                              width="100%"
                              backgroundColor="#F7FAFC"
                              borderBottomRightRadius="10px"
                              borderBottomLeftRadius="10px"
                            >
                              <Flex
                                flexDirection={isMobile ? "column" : "row"}
                                minHeight="84px"
                                padding={isMobile ? "10px 20px" : "10px 0"}
                                justifyContent="space-between"
                                alignItems={isMobile ? "flex-start" : "center"}
                                width="97%"
                              >
                                <Flex
                                  flexDirection="row"
                                  alignItems="center"
                                  marginLeft={isMobile ? "0" : "24px"}
                                >
                                  <Box marginRight="10px" height="22px">
                                    *
                                  </Box>
                                  <Text
                                    fontSize="14px"
                                    fontWeight="600"
                                    color="#1A202C"
                                  >
                                    {t("address_street_cords")}
                                  </Text>
                                </Flex>
                                <Box>
                                  <LocationSelector
                                    setCustomValue={onChange}
                                    errors={{
                                      cordsError:
                                        coordsError || officeCoordsError,
                                    }}
                                    values={office || {}}
                                    userDefaultCoords={{}}
                                    fieldName={`offices.${index}.coordinates`}
                                    key={`offices.${index}.coordinates`}
                                  />
                                </Box>
                              </Flex>
                            </Box>
                            <Box backgroundColor="#F7FAFC" width="100%">
                              <Flex
                                flexDirection={isMobile ? "column" : "row"}
                                minHeight="84px"
                                padding={isMobile ? "10px 20px" : "10px 0"}
                                justifyContent="space-between"
                                alignItems={isMobile ? "flex-start" : "center"}
                                width="97%"
                              >
                                <Flex
                                  flexDirection="row"
                                  alignItems="center"
                                  marginLeft={isMobile ? "0" : "24px"}
                                  minWidth="190px"
                                >
                                  <Text
                                    fontSize="14px"
                                    fontWeight="600"
                                    color="#1A202C"
                                  >
                                    {t("q7_info_phone_label")}
                                  </Text>
                                </Flex>
                                <CustomInput
                                  placeholder={t("q7_info_phone_help")}
                                  containerWidth="100%"
                                  width="435px"
                                  height="32px"
                                  type="text"
                                  name={`offices.${index}.phoneNumber`}
                                  id={`offices.${index}.phoneNumber`}
                                  containerJustifyContent="flex-start"
                                  showErrorMessage={
                                    errors.offices?.[index].phoneNumber
                                  }
                                  errorMessage={
                                    errors?.offices?.[index]?.phoneNumber
                                  }
                                  handleInputChange={(e) =>
                                    onChange(
                                      `offices.${index}.phoneNumber`,
                                      e.target.value
                                    )
                                  }
                                  defaultValue={office.phoneNumber}
                                />
                              </Flex>
                            </Box>
                            <Box backgroundColor="#F7FAFC" width="100%">
                              <Flex
                                flexDirection={isMobile ? "column" : "row"}
                                minHeight="84px"
                                padding={isMobile ? "10px 20px" : "10px 0"}
                                justifyContent="space-between"
                                alignItems={isMobile ? "flex-start" : "center"}
                                width="97%"
                              >
                                <Flex
                                  flexDirection="row"
                                  alignItems="center"
                                  marginLeft={isMobile ? "0" : "24px"}
                                  minWidth="190px"
                                >
                                  <Flex flexDirection="row" alignItems="center">
                                    <Box marginRight="10px" height="22px">
                                      *
                                    </Box>
                                    <Text
                                      fontSize="14px"
                                      fontWeight="600"
                                      color="#1A202C"
                                    >
                                      {t("web_client.office_alias")}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <CustomInput
                                  placeholder="Alias"
                                  containerWidth="100%"
                                  width="435px"
                                  height="32px"
                                  type="text"
                                  id={`offices.${index}.alias`}
                                  name={`offices.${index}.alias`}
                                  containerJustifyContent="flex-start"
                                  showErrorMessage={
                                    errors.offices?.[index].alias
                                  }
                                  errorMessage={errors?.offices?.[index]?.alias}
                                  handleInputChange={(e) =>
                                    onChange(
                                      `offices.${index}.alias`,
                                      e.target.value
                                    )
                                  }
                                  defaultValue={office.alias}
                                />
                              </Flex>

                              <Flex
                                flexDirection={isMobile ? "column" : "row"}
                                minHeight="84px"
                                padding={isMobile ? "10px 20px" : "10px 0"}
                                justifyContent="space-between"
                                alignItems={isMobile ? "flex-start" : "center"}
                                width="97%"
                              >
                                <CountryFormControl>
                                  <CountryFormLabel>
                                    <Text
                                      fontSize="14px"
                                      fontWeight="600"
                                      lineHeight="20px"
                                      color="#1A202C"
                                      mt="5px"
                                    >
                                      {t("web_client.office_owner")}
                                    </Text>
                                  </CountryFormLabel>
                                  <VStack alignItems="flex-start">
                                    <Box>
                                      <CountrySelect
                                        value={office?.officeOwner}
                                        onChange={(e) => {
                                          onChange(
                                            `offices.${index}.officeOwner`,
                                            e.target.value
                                          )
                                        }}
                                        id="officeOwner"
                                        name="officeOwner"
                                      >
                                        {docOrgs
                                          ?.filter((f) =>
                                            accessMode === "business"
                                              ? f.officeOwner !== "private"
                                              : true
                                          )
                                          ?.map((country) => (
                                            <option
                                              key={country.value}
                                              value={country.value}
                                            >
                                              {country.label}
                                            </option>
                                          ))}
                                      </CountrySelect>
                                    </Box>
                                  </VStack>
                                </CountryFormControl>
                              </Flex>

                              <Flex
                                flexDirection={isMobile ? "column" : "row"}
                                minHeight="84px"
                                padding={isMobile ? "10px 20px" : "10px 0"}
                                justifyContent="space-between"
                                alignItems={isMobile ? "flex-start" : "center"}
                                width="97%"
                              >
                                <Flex
                                  flexDirection="row"
                                  alignItems="center"
                                  marginLeft={isMobile ? "0" : "24px"}
                                  minWidth="190px"
                                >
                                  <Box marginRight="10px" height="22px">
                                    *
                                  </Box>
                                  <Text
                                    fontSize="14px"
                                    fontWeight="600"
                                    color="#1A202C"
                                  >
                                    {t("web_client.range")}
                                  </Text>
                                </Flex>
                                <CustomInput
                                  placeholder="Ingrese el rango de cobertura"
                                  containerWidth="100%"
                                  width="435px"
                                  height="32px"
                                  type="text"
                                  id={`offices.${index}.range`}
                                  name={`offices.${index}.range`}
                                  containerJustifyContent="flex-start"
                                  showErrorMessage={
                                    errors.offices?.[index].alias
                                  }
                                  errorMessage={errors?.offices?.[index]?.alias}
                                  handleInputChange={(e) =>
                                    onChange(
                                      `offices.${index}.range`,
                                      parseFloat(e.target.value)
                                    )
                                  }
                                  defaultValue={office.range}
                                />
                              </Flex>
                            </Box>
                          </>
                        )}
                      />
                      <hr />
                    </div>
                  )
                })}
                <Flex
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                  mb="20px"
                  mt="20px"
                >
                  <Button
                    colorScheme="blue"
                    background="transparent"
                    width="100"
                    height="30px"
                    borderRadius="6px"
                    border="1px solid #E2E8F0"
                    color="#000"
                    fontSize="1rem"
                    fontWeight="400"
                    onClick={() =>
                      arrayHelpers.push({
                        state: null,
                        city: null,
                        district: null,
                        addressDetails: null,
                        coordinates: "",
                        id: uuidv4(),
                        officeOwner:
                          accessMode === "business"
                            ? docOrgs?.filter((f) =>
                                accessMode === "business"
                                  ? f.officeOwner !== "private"
                                  : true
                              )?.[0]?.value
                            : "private",
                        default: !(values?.offices?.length >= 1),
                      })
                    }
                    disabled={get(errors, `offices`, false)}
                  >
                    <Text pt="5px" pb="5px">
                      {values?.offices?.length >= 1
                        ? t("web_client.add_another_office")
                        : t("web_client.add_office")}
                    </Text>
                  </Button>
                </Flex>
              </div>
            )}
          />
        </>
      )}
      {!values.multipleOfficesEnabled && (
        <>
          {selectedCountry && (
            <CountryFormControl isInvalid={stateError && hasSubmitions >= 1}>
              <CountryFormLabel>
                <Text
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="20px"
                  color="#1A202C"
                  mt="5px"
                >
                  *{" "}
                  {t(`web_client.country_state_label_${selectedCountry.iso2}`)}
                </Text>
              </CountryFormLabel>
              <VStack alignItems="flex-start">
                <Box>
                  <CountrySelect
                    placeholder={t(
                      `web_client.country_state_label_${selectedCountry.iso2}`
                    )}
                    onChange={(e) => {
                      const stateSelected = selectedCountry.states.find(
                        (i) => i.name === e.target.value
                      )
                      onChange("state", stateSelected.name)
                      setSelectedState(stateSelected)
                    }}
                    id="state"
                    name="state"
                  >
                    {selectedCountry?.states?.map((state) => (
                      <option key={state.id} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </CountrySelect>
                </Box>
                <Box>
                  <FormErrorMessage mt={-1} mb={-2}>
                    {stateError}
                  </FormErrorMessage>
                </Box>
              </VStack>
            </CountryFormControl>
          )}
          {selectedCity && (
            <CountryFormControl isInvalid={cityError && hasSubmitions >= 1}>
              <CountryFormLabel>
                <Text
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="20px"
                  color="#1A202C"
                  mt="5px"
                >
                  * {t(`web_client.country_city_label_${selectedCountry.iso2}`)}
                </Text>
              </CountryFormLabel>
              <VStack alignItems="flex-start">
                <Box>
                  <CountrySelect
                    placeholder={t(
                      `web_client.country_city_label_${selectedCountry.iso2}`
                    )}
                    onChange={(e) => {
                      const selectedCityValue = selectedState.cities.find(
                        (i) => i.name === e.target.value
                      )
                      onChange("city", selectedCityValue.name)
                      setSelectedCity(selectedCityValue)
                    }}
                    id="city"
                    name="city"
                  >
                    {selectedState?.cities?.map((city) => (
                      <option key={city.id} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </CountrySelect>
                </Box>
                <Box>
                  <FormErrorMessage mt={-1} mb={-2}>
                    {cityError}
                  </FormErrorMessage>
                </Box>
              </VStack>
            </CountryFormControl>
          )}
          {selectedCity && selectedCity.districts && (
            <CountryFormControl isInvalid={districtError && hasSubmitions >= 1}>
              <CountryFormLabel>
                <Text
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="20px"
                  color="#1A202C"
                  mt="5px"
                >
                  * {t("web_client.country_district_label_CR")}
                </Text>
              </CountryFormLabel>
              <VStack alignItems="flex-start">
                <Box>
                  <CountrySelect
                    placeholder={t("web_client.country_district_label_CR")}
                    onChange={(e) => {
                      const selectedDistrictValue = selectedCity.districts.find(
                        (i) => i.name === e.target.value
                      )
                      onChange("district", selectedDistrictValue.name)
                      setSelectedDistrict(selectedDistrictValue)
                    }}
                    id="district"
                    name="district"
                  >
                    {selectedCity?.districts?.map((district) => (
                      <option key={district.name} value={district.name}>
                        {district.name}
                      </option>
                    ))}
                  </CountrySelect>
                </Box>
                <Box>
                  <FormErrorMessage mt={-1} mb={-2}>
                    {stateError}
                  </FormErrorMessage>
                </Box>
              </VStack>
            </CountryFormControl>
          )}
        </>
      )}
    </>
  )
}

export default CountriesSelector
