/* eslint-disable no-console */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from "react"
import PropTypes from "prop-types"
import has from "lodash.has"
import get from "lodash.get"
import isEmpty from "lodash.isempty"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"
import { v4 as uuidv4 } from "uuid"
import Creatable from "react-select/creatable"
import { withTrans } from "../../../i18n/withTrans"
import MedicalForm from "../../molecules/MedicalForm"
import PreviewMessage from "../../molecules/PreviewMessage"
import Room from "../../molecules/Room"
import ChatHeader from "../../molecules/ChatHeader"
import ChatContainer from "../../molecules/ChatContainer"
import ChatFooter from "../../molecules/ChatFooter"
import Modal from "../../atoms/CustomModal"
import { showBrowserNotification, prepareMessage } from "../../../utils/helpers"
import { withFirebase } from "../../../utils/Firebase"
import Theme from "../../../styles/theme"

import {
  videoGenerateAccessTokenUrl,
  cancelSessionUrl,
} from "../../../utils/App"
import {
  ChatWrapper,
  Overlay,
  ModalWrapper,
  ModalOptions,
  RegularFont,
  DiagnosisModal,
  RegularFontBlack,
} from "./styles"

const customStyles = {
  container: (provided) => ({
    ...provided,
    marginLeft: 2,
  }),
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderRadius: state.selectProps.borderRadius,
    borderColor: state.selectProps.showError
      ? Theme.color.alert
      : Theme.color.iceBlue,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"

    return { ...provided, opacity, transition }
  },
}

class Chat extends Component {
  constructor(props) {
    super(props)
    this.messagesRef = props.firebase.subscribeToMessages({
      chat: props.session,
    })
    this.serverTimeOffsetRef = null
    this.state = {
      medicalForm: null,
      medicalFormVisible: false,
      currentMessages: [],
      targetTokens: [],
      twilioToken: null,
      videoVisible: false,
      messagesLoaded: false,
      content: null,
      fullMessage: null,
      previewMessageVisible: false,
      messageType: null,
      newVideoSession: null,
      serverTimeOffset: 0,
      referenceKeys: [],
      messageKey: null,
      sessionNoShow: false,
      isModalOpen: false,
      displayWaitButton: true,
      isVideoModalOpen: false,
      isExtendTimeOpen: false,
      isNotesVisible: false,
      isDiagnosisModalVisible: false,
      returnState: {},
      diagnoses: [],
      causes: [],
      patientNeedsDisability: false,
      patientDisabilityDays: 0,
      selectedDiagnosis: null,
      selectedCause: null,
      isContact: false,
    }
  }

  componentDidMount() {
    const { firebase, history } = this.props
    this.getTargetTokens()
    this.fetchTwilioToken()
    this.getCausesAndDiagnoses()
    if (history && history.location.state && history.location.state.session) {
      const state = { ...history.location.state }
      delete state.session
      history.replace({ ...history.location, state })
    }
    if (firebase) {
      this.serverTimeOffsetRef = firebase.getServerTimeOffsetRef()
      this.serverTimeOffsetRef.on("value", this.onServerTimeValue, this.onError)
    }
    // this.handleLoadMore()
  }

  componentDidUpdate(prevProps, prevState) {
    const { session, firebase, messages, customLimit } = this.props
    const { messagesLoaded } = this.state
    if (prevProps.session !== session) {
      this.setState({ messagesLoaded: false })
      if (this.messagesRef) {
        this.messagesRef.off("child_added", this.onChildAdded)
      }
      this.setState({
        currentMessages: [],
      })
      this.messagesRef = firebase.subscribeToMessages({
        chat: session,
      })
    }
    if (messages !== prevProps.messages) {
      // eslint-disable-next-line no-unused-vars
      const noShowMessages = messages.filter(
        (m) => m.flag === "noshowclient" && m.showTo === "provider"
      )
      this.setState(
        {
          currentMessages: messages,
        },
        () => {
          this.setState({ messagesLoaded: true })
        }
      )
    }
    if (messagesLoaded !== prevState.messagesLoaded && messagesLoaded) {
      if (this.messagesRef) {
        this.messagesRef
          .orderByChild("createdAt")
          .limitToLast(customLimit)
          .on("child_added", this.onChildAdded, this.onError)
      }
    }
  }

  componentWillUnmount() {
    if (this.messagesRef) {
      this.messagesRef.off("child_added", this.onChildAdded)
    }
    if (this.serverTimeOffsetRef) {
      this.serverTimeOffsetRef.off("value", this.onServerTimeValue)
    }
  }

  async getCausesAndDiagnoses() {
    const { firebase } = this.props

    try {
      const causes = await firebase.getCauses()

      const causesArray = []
      causes.forEach((cause) => {
        causesArray.push(cause.data())
      })

      this.setState({ causes: causesArray })

      const diagnoses = await firebase.getDiagnoses()

      const diagnosesArray = []
      diagnoses.forEach((diagnosis) => {
        diagnosesArray.push(diagnosis.data())
      })

      this.setState({ diagnoses: diagnosesArray })
    } catch (error) {
      window.Honeybadger?.notify(error)
    }
  }

  getTargetTokens() {
    const { session, firebase, info } = this.props
    const { targetTokens } = this.state
    if (isEmpty(targetTokens)) {
      const storedTargetTokens = localStorage.getItem(`targetTokens-${session}`)
      if (storedTargetTokens === null && info) {
        firebase
          .getProfile({
            email: info.clientEmail,
          })
          .then((snapshot) => {
            if (snapshot.exists) {
              const targetData = snapshot.data() || []
              const { messagingTokens } = targetData || {}
              this.setState({
                targetTokens: messagingTokens,
              })
              localStorage.setItem(
                `targetTokens-${session}`,
                JSON.stringify(messagingTokens)
              )
            }
          })
      } else if (storedTargetTokens && storedTargetTokens !== undefined) {
        this.setState({
          targetTokens: JSON.parse(storedTargetTokens || "[]"),
        })
      } else {
        console.warn("No target tokens found")
      }
    }
  }

  onServerTimeValue = (snapshot) => {
    this.setState({
      serverTimeOffset: snapshot.val(),
    })
  }

  onError = (_error) => {
    console.error("Error: -- chats --", _error)
  }

  onChildAdded = async (snapshot) => {
    // eslint-disable-next-line no-unused-vars
    const { messageKeys, info, firebase, t } = this.props

    // eslint-disable-next-line react/destructuring-assignment
    const { sessionNoShow, videoVisible } = this.state
    const messageData = snapshot.val()

    const messageId = messageData.uploadId || messageData._id
    if (!has(messageKeys, messageId)) {
      if (
        messageData.flag === "noshowclient" &&
        messageData.showTo === "provider" &&
        info.clientWarningsCount <= 2 && // empty messages prop indicates that is coming from (Prescription, Clinical, Images)
        !sessionNoShow
      ) {
        this.setState({
          sessionNoShow: true,
          isModalOpen: true,
          displayWaitButton: messageData.displayedWarnings < 2,
        })
        const Settings = await firebase.getSettings({
          email: info.providerEmail,
          key: "notifications",
        })
        if (Settings.data() && Settings.data().onNoShowBrowser) {
          showBrowserNotification(
            t("session_noshow_alert", { name: get(info, "clientName", "") })
          )
        }
      }

      // Check if there is an incoming videocall request
      if (
        messageData.system &&
        /^video_session_started\|/.test(messageData.text) &&
        info.providerEmail !== messageData.user._id &&
        !videoVisible
      ) {
        const msgArr = messageData.text.split("|")
        this.videoMsg = t("join_video_call", {
          name: msgArr[2],
        })
        this.setState({ isVideoModalOpen: true })
      }

      if (
        messageData.system &&
        messageData.text === "session_timeout_warning"
      ) {
        this.setState({ isExtendTimeOpen: true })
      }

      if (
        messageData.system &&
        messageData.text === "system_session_finished"
      ) {
        localStorage.removeItem("diagnosisAdded")
      }

      if (messageData.user && info.providerEmail !== messageData.user._id) {
        this.setState({
          sessionNoShow: false,
          isModalOpen: false,
          displayWaitButton: false,
        })
      }
      this.setState((prevState) => ({
        currentMessages: [
          ...prevState.currentMessages,
          { ...messageData, key: snapshot.key },
        ],
        newVideoSession: null,
      }))
    }
    this.setState((prevState) => ({
      referenceKeys: [
        ...prevState.referenceKeys.filter((rk) => rk.key !== snapshot.key),
        {
          id: messageId,
          key: snapshot.key,
        },
      ],
    }))
  }

  handleNotesClick = () => {
    const { isNotesVisible: currentVisible } = this.state
    this.setState({ isNotesVisible: !currentVisible })
  }

  handleDiagnosisClick = (event, stateFromTo) => {
    const { history } = this.props
    const diagnosisAdded = localStorage.getItem("diagnosisAdded")

    if (diagnosisAdded && stateFromTo) {
      if (stateFromTo?.pathname === "notes") {
        this.handleNotesClick()
      } else {
        history.replace(stateFromTo?.pathname, {
          ...stateFromTo?.state,
        })
      }
    } else {
      event.preventDefault()
      const { isDiagnosisModalVisible: currentModalVisible } = this.state
      this.setState({
        isDiagnosisModalVisible: !currentModalVisible,
        returnState: stateFromTo,
      })
    }
  }

  handleMessageClick = (message) => {
    const { readOnlyChat, isFromContacts } = this.props
    const { referenceKeys } = this.state
    const msgKey = referenceKeys.find((rk) => rk.id === message._id)
    if (!readOnlyChat || isFromContacts) {
      if (message.type === "medical") {
        this.setState({
          medicalForm: {
            data: message.form,
            owner: message.profileOwner,
            type: message.profileType,
          },
          medicalFormVisible: true,
        })
      } else if (message.type === "prescription") {
        this.setState({
          fullMessage: message,
          content: message.prescription,
          previewMessageVisible: true,
          messageType: "prescription",
          messageKey: msgKey.key,
        })
      } else if (message.type === "clinical") {
        this.setState({
          fullMessage: message,
          content: message.clinical,
          previewMessageVisible: true,
          messageType: "clinical",
          messageKey: msgKey.key,
        })
      } else if (message.type === "medicalImages") {
        this.setState({
          fullMessage: message,
          content: message.medicalImages,
          previewMessageVisible: true,
          messageType: "medicalImages",
          messageKey: msgKey.key,
        })
      }
    }
  }

  handleLoadMore = () => {
    const { setLoadMore } = this.props
    setLoadMore()
  }

  handleVideoClick = () => {
    this.setState({
      videoVisible: true,
      isVideoModalOpen: false,
    })
  }

  handleMedicalClose = () => {
    this.setState({
      medicalForm: null,
      medicalFormVisible: false,
    })
  }

  handlePreviewClose = () => {
    this.setState({
      fullMessage: null,
      content: null,
      previewMessageVisible: false,
      messageType: null,
    })
  }

  handleLogout = () => {
    this.setState({
      videoVisible: false,
    })
  }

  handleNewVideoSession = (displayName) => {
    this.setState({
      newVideoSession: displayName || null,
    })
  }

  sendMessage = async (msg) => {
    const { session, user, firebase } = this.props
    const { serverTimeOffset } = this.state
    const prepMsg = prepareMessage(
      user,
      serverTimeOffset,
      msg,
      msg.type || "text",
      msg.targetTokens || []
    )
    await firebase.pushMessage({ chat: session, message: prepMsg })
  }

  handleFinishSession = async () => {
    const { firebase, session, history, info } = this.props
    try {
      localStorage.removeItem("diagnosisAdded")
      const authToken = await firebase.getIdToken()
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chatId: session,
          clientEmail: info.clientEmail,
          clientName: info.clientName,
          providerEmail: info.providerEmail,
          noShow: true,
        }),
      }
      const response = await fetch(cancelSessionUrl, options)
      const data = await response.json()
      if (data) {
        this.setState({ isModalOpen: false })
        history.push("/sessions")
      }
    } catch (e) {
      window.Honeybadger?.notify(e)
    }
  }

  handleExtendSession = async () => {
    const { firebase, session } = this.props
    const { targetTokens } = this.state
    try {
      await firebase.addWaitingMinutes({
        chat: session,
      })
      await this.sendMessage({
        _id: uuidv4(),
        text: `session_extended`,
        type: "system",
        targetTokens,
      })
      this.setState({
        isExtendTimeOpen: false,
      })
    } catch (error) {
      window.Honeybadger?.notify(error)
    }
  }

  handlePatientNeedsDisability = (e) => {
    e.persist()
    const { patientNeedsDisability } = this.state

    this.setState({
      patientNeedsDisability: !patientNeedsDisability,
    })
  }

  handleCreateCause = async (inputValue) => {
    try {
      const { causes } = this.state
      const { firebase } = this.props

      const newCause = {
        label: inputValue,
        value: inputValue.toLowerCase().replace(/\s+/g, "_"),
      }
      this.setState({
        causes: [...causes, newCause],
        selectedCause: newCause,
      })

      await firebase.createCauseOrDiagnose({
        collection: "causes",
        record: newCause,
      })
    } catch (error) {
      window.Honeybadger?.notify(error)
    }
  }

  handleCreateDiagnose = async (inputValue) => {
    try {
      const { diagnoses } = this.state
      const { firebase } = this.props
      const newDiagnose = {
        label: inputValue,
        value: inputValue.toLowerCase().replace(/\s+/g, "_"),
      }
      this.setState({
        diagnoses: [...diagnoses, newDiagnose],
        selectedDiagnosis: newDiagnose,
      })

      await firebase.createCauseOrDiagnose({
        collection: "diagnoses",
        record: newDiagnose,
      })
    } catch (error) {
      window.Honeybadger?.notify(error)
    }
  }

  handleCreateNewDiagnose = async () => {
    const {
      returnState,
      selectedDiagnosis,
      selectedCause,
      patientNeedsDisability,
      patientDisabilityDays,
    } = this.state
    const { history, firebase, session, user } = this.props

    try {
      const recordData = {
        diagnosis: selectedDiagnosis,
        cause: selectedCause,
        patientNeedsDisability,
        patientDisabilityDays,
        createdAt: Date.now(),
        createdByEmail: user.isImpersonating
          ? user.impersonatingEmail
          : user.email,
        createdBy: `${user?.medicProfile?.title} ${user?.medicProfile?.displayName}`,
        sessionId: session,
        type: "diagnosis",
      }

      await firebase.addMedicalRecord({
        contactEmail: returnState?.state?.contact?.email,
        record: recordData,
      })

      localStorage.setItem("diagnosisAdded", true)
      this.setState({
        isDiagnosisModalVisible: false,
      })

      setTimeout(() => {
        if (returnState?.pathname === "notes") {
          this.handleNotesClick()
        } else {
          history.replace(returnState?.pathname, {
            ...returnState?.state,
          })
        }
      }, 500)
    } catch (error) {
      window.Honeybadger?.notify(error)
    }
  }

  handleAddingContact = async (added) => {
    this.setState({ isContact: added })
  }

  // Get a Twilio token if it doesn't have one
  async fetchTwilioToken() {
    const { session, user, firebase } = this.props
    const { twilioToken } = this.state
    if (twilioToken === null) {
      const storedToken = localStorage.getItem(`twilioToken-${session}`)
      if (storedToken === null) {
        firebase
          .getIdToken()
          .then((token) => {
            fetch(videoGenerateAccessTokenUrl, {
              method: "POST",
              body: JSON.stringify({
                client: user.isImpersonating
                  ? user.impersonatingEmail
                  : user.email,
                room: session,
              }),
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            })
              .then((response) => {
                if (response.ok) {
                  return response.json()
                }
                throw new Error(
                  `Unexpected Response from Server: ${response.status}`
                )
              })
              .then((data) => {
                const tmpToken = get(data, "token", null)
                localStorage.setItem(
                  `twilioToken-${session}`,
                  JSON.stringify(tmpToken)
                )
                this.setState({
                  twilioToken: tmpToken,
                })
              })
              .catch((err) => {
                window.Honeybadger?.notify(err)
              })
          })
          .catch((err) => {
            window.Honeybadger?.notify(err)
          })
      } else {
        this.setState({
          twilioToken: JSON.parse(storedToken),
        })
      }
    }
  }

  render() {
    const { user, firebase, info, session, readOnlyChat, t } = this.props
    const {
      medicalFormVisible,
      currentMessages,
      medicalForm,
      twilioToken,
      videoVisible,
      content,
      previewMessageVisible,
      messageType,
      newVideoSession,
      messageKey,
      serverTimeOffset,
      isModalOpen,
      displayWaitButton,
      fullMessage,
      isVideoModalOpen,
      isExtendTimeOpen,
      isNotesVisible,
      isDiagnosisModalVisible,
      diagnoses,
      causes,
      patientNeedsDisability,
      patientDisabilityDays,
      selectedDiagnosis,
      selectedCause,
      isContact,
    } = this.state

    const isDependentChat =
      currentMessages &&
      currentMessages.length > 0 &&
      currentMessages.find(
        (m) => m.profileType === "Dependent" || m.profileType === "Dependiente"
      )

    return (
      <ChatWrapper>
        {!medicalFormVisible && !videoVisible && !previewMessageVisible && (
          <>
            <ChatHeader
              user={user}
              session={session}
              info={info}
              name={get(info, "clientEmail", "")}
              handleClick={this.handleVideoClick}
              showButton={!readOnlyChat}
              serverTimeOffset={serverTimeOffset}
              orderId={get(info, "orderId")}
              donated={get(info, "donated")}
              sessionDiscount={get(info, "sessionDiscount")}
              isDependentChat={isDependentChat}
              isContact={isContact}
              handleAddingContact={this.handleAddingContact}
            />
            <ChatContainer
              key="chat-container"
              messages={currentMessages}
              user={user}
              handleClick={this.handleMessageClick}
              handleLoadMore={this.handleLoadMore}
              session={session}
              isDependentChat={isDependentChat}
              isNotesVisible={isNotesVisible}
              info={info}
              handleNotesClick={this.handleNotesClick}
            />
            {!readOnlyChat && (
              <ChatFooter
                user={user}
                session={session}
                info={info}
                newVideoSession={newVideoSession}
                serverTimeOffset={serverTimeOffset}
                isDependentChat={isDependentChat}
                handleNotesClick={this.handleNotesClick}
                handleDiagnosisClick={this.handleDiagnosisClick}
                isContact={isContact}
                handleAddingContact={this.handleAddingContact}
              />
            )}
          </>
        )}
        {medicalFormVisible && (
          <>
            <MedicalForm
              handleClose={this.handleMedicalClose}
              message={medicalForm}
              info={info}
            />
            <Overlay />
          </>
        )}
        {previewMessageVisible && (
          <>
            <PreviewMessage
              handleClose={this.handlePreviewClose}
              messageKey={messageKey}
              content={content}
              info={info}
              user={user}
              firebase={firebase}
              type={messageType}
              fullMessage={fullMessage}
            />
            <Overlay />
          </>
        )}
        {videoVisible && (
          <>
            <Room
              token={twilioToken}
              roomName={session}
              handleLogout={this.handleLogout}
              info={info}
              user={user}
              onNewSession={this.handleNewVideoSession}
            />
            <Overlay />
          </>
        )}
        {isVideoModalOpen && (
          <Modal
            modalOpen={isVideoModalOpen}
            setModalOpen={(status) => {
              this.setState({ isVideoModalOpen: status })
            }}
            showClose={false}
          >
            <ModalWrapper isFull>
              <ModalOptions>
                <section>
                  <p>{this.videoMsg}</p>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        this.setState({
                          isVideoModalOpen: false,
                        })
                      }}
                    >
                      {t("cancel")}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        this.handleVideoClick()
                      }}
                    >
                      {t("confirm")}
                    </button>
                  </div>
                </section>
              </ModalOptions>
            </ModalWrapper>
          </Modal>
        )}
        {isModalOpen && (
          <Modal
            modalOpen={isModalOpen}
            setModalOpen={(status) => {
              this.setState({ isModalOpen: status })
            }}
            showClose={false}
            size="lg"
          >
            <ModalWrapper isFull>
              <ModalOptions>
                <section>
                  <p>{t("no_show_message_client")}</p>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        this.handleFinishSession()
                      }}
                    >
                      {t("session_cancel")}
                    </button>
                    {displayWaitButton && (
                      <button
                        type="button"
                        onClick={async () => {
                          firebase.dismissClientWarning({
                            chat: session,
                          })
                          this.setState({
                            isModalOpen: false,
                            sessionNoShow: false,
                          })
                        }}
                      >
                        {t("wait")}
                      </button>
                    )}
                  </div>
                </section>
              </ModalOptions>
            </ModalWrapper>
          </Modal>
        )}
        {isExtendTimeOpen && (
          <Modal
            modalOpen={isExtendTimeOpen}
            setModalOpen={(status) => {
              this.setState({ isExtendTimeOpen: !status })
            }}
            showClose={false}
          >
            <ModalWrapper isFull>
              <ModalOptions>
                <section>
                  <p>{t("extend_session_message")}</p>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        this.setState({ isExtendTimeOpen: false })
                      }}
                    >
                      {t("cancel")}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        this.handleExtendSession()
                      }}
                    >
                      {t("extend_button")}
                    </button>
                  </div>
                </section>
              </ModalOptions>
            </ModalWrapper>
          </Modal>
        )}
        {isDiagnosisModalVisible && (
          <Modal modalOpen={isDiagnosisModalVisible} showClose={false}>
            <ModalWrapper>
              <DiagnosisModal>
                <section>
                  <RegularFontBlack>
                    {t("web_client.diagnosis_paragraph")}
                  </RegularFontBlack>

                  <RegularFont>
                    {t("web_client.diagnosis_disclaimer")}
                  </RegularFont>

                  <section style={{ marginTop: 30, marginLeft: 15 }}>
                    <RegularFontBlack color="black">
                      {t("web_client.select_diagnosis")}
                    </RegularFontBlack>

                    <Creatable
                      styles={customStyles}
                      options={diagnoses}
                      value={selectedDiagnosis}
                      onChange={(selectedOption) =>
                        this.setState({ selectedDiagnosis: selectedOption })
                      }
                      isClearable
                      isSearchable
                      isCreatable
                      createOptionPosition="first"
                      onCreateOption={this.handleCreateDiagnose}
                      width="300px"
                      placeholder={t("web_client.select_diagnosis")}
                      formatCreateLabel={(inputValue) => (
                        <b>
                          {`${t("web_client.create_diagnose")}: ${inputValue}`}
                        </b>
                      )}
                    />
                  </section>

                  <section style={{ marginTop: 30, marginLeft: 15 }}>
                    <RegularFontBlack color="black">
                      {t("web_client.select_cause")}
                    </RegularFontBlack>

                    <Creatable
                      styles={customStyles}
                      options={causes}
                      value={selectedCause}
                      onChange={(selectedOption) =>
                        this.setState({ selectedCause: selectedOption })
                      }
                      isClearable
                      isSearchable
                      isCreatable
                      createOptionPosition="first"
                      onCreateOption={this.handleCreateCause}
                      width="300px"
                      placeholder={t("web_client.select_cause")}
                      formatCreateLabel={(inputValue) => (
                        <b>
                          {`${t("web_client.create_cause")}: ${inputValue}`}
                        </b>
                      )}
                    />
                  </section>

                  <section style={{ marginTop: 30, marginLeft: 15 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <RegularFont>
                        {t("web_client.patient_needs_disability")}
                      </RegularFont>

                      <input
                        type="checkbox"
                        name="NeedsDisability"
                        checked={patientNeedsDisability}
                        onChange={this.handlePatientNeedsDisability}
                        style={{ marginLeft: "10px" }}
                      />
                    </div>

                    {patientNeedsDisability && (
                      <div style={{ marginTop: 30 }}>
                        <RegularFont>
                          {t("web_client.patient_disability_time")}
                        </RegularFont>

                        <input
                          name="patientDisabilityDays"
                          id="patientDisabilityDays"
                          type="number"
                          onChange={(e) =>
                            this.setState({
                              patientDisabilityDays: Number(e.target.value),
                            })
                          }
                          props={{
                            value: patientDisabilityDays,
                          }}
                          style={{
                            width: "80px",
                            height: "32px",
                            marginTop: 10,
                            border: "1px solid #E2E8F0",
                            borderRadius: "5px",
                            paddingLeft: 5,
                          }}
                        />
                      </div>
                    )}
                  </section>

                  <div style={{ marginTop: 50, paddingBottom: 50 }}>
                    <button
                      disabled={!selectedCause && !selectedDiagnosis}
                      type="button"
                      onClick={this.handleCreateNewDiagnose}
                    >
                      {t("create_register")}
                    </button>
                  </div>
                </section>
              </DiagnosisModal>
            </ModalWrapper>
          </Modal>
        )}
      </ChatWrapper>
    )
  }
}

Chat.defaultProps = {
  readOnlyChat: false,
  isFromContacts: false,
}

Chat.propTypes = {
  t: PropTypes.func.isRequired,
  firebase: PropTypes.shape().isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  messageKeys: PropTypes.shape().isRequired,
  readOnlyChat: PropTypes.bool,
  history: PropTypes.func.isRequired,
  isFromContacts: PropTypes.bool,
}

const ChatCompose = compose(withTrans, withFirebase, withRouter)(Chat)

export default ChatCompose
